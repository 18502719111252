.clientTransactionBed {
  display: flex;
  justify-content: center;
}

.clientTransactionHeadingBed {
  display: flex;
  justify-content: center;
}

.clientTransactionHeading {
  color: #ffff;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  margin: 5px;
}

.clientTransactionShowAllButton {
  color: #001046;
  background-color: #ffff;
  border-radius: 7px;
  padding: 5px;
  margin-left: 20px;
}

.clientTransactionCell {
  background-color: #ffff;
  border-radius: 7px;
  padding: 10px;
  margin: 5px;
}

.clientTransaction {
  display: flex;
}

.clientTransactionCellLabel {
  color: #001055;
  font-weight: 600;
  margin-right: 10px;
}

.clientTransactionCellText {
  color: #001055;
  font-weight: 300;
}

.transactionHistoryButtonBed {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.transactionHistoryButton {
  color: #001055;
  background-color: #ffff;
  font-weight: 400;
  padding: 10px;
  border-radius: 7px;
  text-align: center;
  width: 20%;
  cursor: pointer;
}

.gamePlayBed {
  margin: 30px;
}

.gamePlayHeaderBed {
  display: flex;
  justify-content: center;
}

.gamePlayShowAllButton {
  color: #001046;
  background-color: #ffff;
  width: 100px;
  text-align: center;
  border-radius: 7px;
  padding: 5px;
  margin-left: 20px;
}

.gamePlayDataBed {
  display: flex;
  justify-content: center;
  margin: 22px;
}

.gamePlayDataRowBed {
  display: flex;
}

.gamePlayDataRowLabel {
  color: #ffff;
  font-size: 1.2rem;
  font-weight: 400;
}

.gamePlayDataLeftColBed {
}

.gamePlayDataLeftColPair {
  display: flex;
  margin: 2px;
}

.gamePlayDataValue {
  color: #ffff;
  font-size: 1.2rem;
  font-weight: 200;
  margin-left: 7px;
}

.gamePlayResulBed {
  color: rgb(0, 28, 59);
  background-color: #ffff;
  padding: 5px;
  margin-left: 30px;
  border-radius: 7px;
}

.gamePlayResulHeader {
  text-align: center;
  font-weight: 900;
}

.gamePlayResultRowContainer {
  display: flex;
}

.gamePlayResultRowBed {
  display: flex;
}

.gamePlayResultRowSymbolBed {
  margin: 3px;
}

.gamePlayResultRowQuantityBed {
  margin: 3px;
}

.gamePlayButton {
  color: #001055;
  background-color: #ffff;
  font-weight: 400;
  padding: 10px;
  border-radius: 7px;
  text-align: center;
  width: 20%;
  cursor: pointer;
}

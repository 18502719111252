html,
body {
  padding: 0;
  margin: 0;
  background: #072333;
  height: 100%;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Pacifico';
  src: local('Pacifico'),
    url(../assets/fonts/pacifico/Pacifico.ttf) format('truetype');
}

@font-face {
  font-family: 'Dites';
  src: local('Dites'), url(../assets/fonts/dites//Dited.otf) format('truetype');
}

@font-face {
  font-family: 'Chunk';
  src: local('Chunk'), url(../assets/fonts/chunk//Chunk.otf) format('truetype');
}

@import url('https://use.typekit.net/yjs2lsy.css');

*,
*:before,
*:after {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}
::-moz-selection {
  background: transparent;
}
::selection {
  background: transparent;
}

.listNoData {
  color: #ffff;
  font-size: 1.5rem;
  font-weight: 200;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Form */
.formNoneEditableField {
  background-color: #8b8b8b;
  border-radius: 7px;
  padding: 5px;
  text-align: center;
}

.formOpenButtonBed {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.formOpenButton {
  color: #ffff;
  background-color: #706f6f;
  width: 300px;
  border-radius: 7px;
  padding: 8px 0px;
  text-align: center;
  cursor: pointer;
}
.formOpenButton:active {
  background-color: #8d8a8a;
}

.formCancelButton {
  color: #ffff;
  display: flex;
  margin-top: 10px;
  cursor: pointer;
}

.formCancelButtonIcon {
  margin-top: 2px;
  margin-right: 5px;
}

.formBed {
  background-color: #002b44;
  border: 1px solid #ffff;
  position: absolute;
  width: 300px;
  font-size: 1.2rem;
  border-radius: 7px;
  padding: 0px 20px 15px;
}

.formHeader {
  color: #ffff;
  font-weight: 800;
  font-size: 1.5rem;
  text-align: center;
  margin: 10px;
}

.formBedNoBorder {
  width: 300px;
  font-size: 1.2rem;
  border-radius: 7px;
}

.formInput {
  height: 30px;
  width: auto;
  border-radius: 7px;
  text-align: center;
  border-style: none;
  outline: none !important;
}

.formInputHeader {
  color: #ffff;
  font-weight: 300;
  margin: 10px 0 2px;
}

.formSubmitButtonBed {
  display: flex;
  justify-content: center;
}

.formSubmitButton {
  color: #ffff;
  background-color: #706f6f;
  border-radius: 7px;
  width: 40%;
  padding: 5px;
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
}
.formSubmitButton:active {
  background-color: #918f8f;
}

.formErrorBed {
  background-color: #ffff;
  border: 3px solid #ff0000;
  border-radius: 7px;
  padding: 10px;
  margin-top: 10px;
}

.formErrorText {
  color: #ff0000;
  font-size: 1.3rem;
  text-align: center;
}

.formErrorButtonBed {
  display: flex;
  justify-content: center;
}

.formErrorButton {
  color: #ffff;
  background-color: #ff0000;
  border-radius: 7px;
  text-align: center;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
}
.formErrorButton:active {
  background-color: #c90202;
}

.formSuccessBed {
  position: absolute;
  background-color: #000e29;
  border: 3px solid #00ffff;
  border-radius: 7px;
  padding: 10px;
  margin-top: 10px;
}

.formSuccessText {
  color: #00ffff;
  font-size: 1.3rem;
  text-align: center;
}

.formSuccessButtonBed {
  display: flex;
  justify-content: center;
}

.formSuccessButton {
  color: #000e29;
  background-color: #00ffff;
  border-radius: 7px;
  text-align: center;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
}
.formSuccessButton:active {
  background-color: #04dfdf;
}

/* Dashboard */
.dashWindowBed {
  border: 1px solid #ffff;
  border-radius: 7px;
  padding: 15px;
  width: 332px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.dashWindowHeader {
  color: #ffff;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 15px;
  text-align: center;
}

.dashboardNavLinkButtonBed {
  display: flex;
  justify-content: center;
}

.dashboardNavLinkButton {
  color: #000c3f;
  background-color: #ffff;
  font-size: 1.2rem;
  width: 300px;
  border-radius: 7px;
  padding: 8px 0px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
.dashboardNavLinkButton:active {
  background-color: #ececec;
}

/* Users list */
.listBed {
  display: flex;
  justify-content: center;
}

.listContainer {
  flex: 1;
  margin: 2% 1% 1% 1%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.listForHeader {
  color: #ffff;
  display: flex;
  justify-content: center;
  margin: 15px 50px 0 50px;
}

.usersListCell {
  border: 3px solid #ffff;
  border-radius: 7px;
  padding: 5px;
  margin: 10px;
}

.usersListDataRow {
  color: #ffff;
  display: flex;
}

.usersListDataLabel {
  font-size: 700;
  margin-right: 5px;
}

.usersListDataText {
  font-weight: 200;
}

.listCellButtonBed {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 0 12px;
}

.listCellButton {
  color: #ffff;
  background-color: #706f6f;
  padding: 5px;
  margin: 2px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.listCellButton:active {
  background-color: #918f8f;
}

.listSearchBed {
  height: 100px;
}

.listRegisterOrCreateBed {
  display: flex;
  justify-content: center;
}

.usersListButtonSelectorBed {
  display: flex;
  justify-content: center;
  width: ;
}

.usersListPerDeleteNoteBed {
  position: absolute;
  background-color: #ffff;
  border: 5px solid red;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: -70px;
}

.usersListPerDeleteNote {
  color: red;
  margin: 15px 0;
}

.listEditFormBed {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.formCheckBoxBed {
  color: #ffff;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.formCheckBoxCell {
  display: flex;
}

.formCheckBoxText {
  margin-right: 10px;
}

/* Search bar */
.searchInputBed {
  display: flex;
  justify-content: center;
}

.searchInput {
  background-color: #ffff;
  border-radius: 25px;
  width: 300px;
  height: 30px;
  margin-top: 20px;
  text-align: center;
}

.siteDetailSelectorButtonBed {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.siteDetailSelectorButton {
  color: #ffff;
  border: 3px solid #ffff;
  border-radius: 25px;
  font-size: 1.2rem;
  padding: 12px;
  margin: 10px;
  width: 120px;
  text-align: center;
  cursor: pointer;
}

.siteDetailSelectorButtonActive {
  color: #003253;
  background-color: #ffff;
  border: 3px solid #003253;
  border-radius: 25px;
  font-size: 1.2rem;
  padding: 12px;
  margin: 10px;
  width: 120px;
  text-align: center;
  cursor: pointer;
}

.cashLogsBed {
  display: flex;
  justify-content: center;
}

.cashLogsContainer {
  width: 40%;
  margin-bottom: 25px;
}

.cashLogsButton {
  background-color: aliceblue;
  align-self: center;
  width: 150px;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  padding: 1.1vh;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
}

.cashLogsButtonInactive {
  background-color: #8d8d8d;
  align-self: center;
  width: 150px;

  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  padding: 1.1vh;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
}

.cashLogsCalendarButtonBed {
  display: flex;
  justify-content: center;
}

.cashLogsSelectedDatesBed {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.cashLogsCalendarContainer {
  display: flex;
  justify-content: center;
}

.cashLogsSelectedDatesContainer {
}

.cashLogsSelectedDatesHeading {
  color: #ffff;
  font-size: 3vh;
  margin: 15px;
  font-weight: 800;
}

.cashLogsSelectedDatesText {
  color: #ffff;
  font-size: 3vh;
  margin: 15px;
  font-weight: 300;
}

.cashLogsCalendarDatesError {
  color: rgb(255, 94, 94);
  font-size: 3vh;
  margin: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.requestedLogsBed {
  margin-top: 20%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.requestedLogsDates {
  display: flex;
}

.requestedLogsDatesCell {
  flex: 1;
}

.requestedLogsDateText {
  color: #ffff;
  font-size: 3vh;
  font-weight: 600;
  text-align: center;
}

.requestedLogsChart {
  background-color: #ffff;
  padding: 4vh 0 4vh 0;
  border-radius: 15px;
  margin-top: 40px;
}

.requestedLogsChartText {
  color: #0f496b;
  font-size: 4vh;
  font-weight: 600;
  line-height: 6vh;
  text-align: center;
}

.requestedLogsButtonBed {
  display: flex;
  justify-content: center;
}

.requestedLogsButton {
  background-color: #ffff;
  color: #0f496b;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 16px;
  border-radius: 7px;
  margin-top: 4vh;
  cursor: pointer;
}

.commissionAccountBed {
  border: 1px solid #ffff;
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
}

.commissionAccountHeader {
  color: #ffff;
  font-size: 3.5vh;
  font-weight: 100;
  text-align: center;
  margin-bottom: 15px;
}

.commissionRow {
  display: flex;
  justify-content: center;
}

.unpaidTransactionBed {
  display: flex;
  justify-content: center;
}

.unpaidTransactionContainer {
  background-color: black;
  width: 90%;
  height: 300px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 30px;
}

.unpaidTransactionRefreshButtonSpacer {
  margin-bottom: 15px;
}

.unpaidTransactionRow,
.unpaidTransactionHeaderBed {
  display: flex;
  flex: 7;
}

.unpaidTransactionText {
  color: #ffff;
  flex: 1;
}

.unpaidTransactionHeader {
  color: #ffff;
  flex: 1;
  margin-bottom: 10px;
}

.commissionAccountCreatorButtonBed {
  display: flex;
  justify-content: center;
}

.commissionAccountCreatorButton {
  background-color: blue;
  color: #ffff;
  padding: 10px 15px;
  margin-top: 7px;
  border-radius: 7px;
  cursor: pointer;
}

.commissionAccountListItemBed {
  display: flex;
  justify-content: center;
}

.commissionAccountListItemContainer {
  border: 1px solid #ffff;
  border-radius: 7px;
  padding: 7px;
  margin: 15px 7px;
}

.commissionAccountListItemRow {
  display: flex;
}

.commissionAccountListItemLabel {
  color: #ffff;
  margin-right: 5px;
}

.commissionAccountListItemText {
  color: #ffff;
  font-weight: 100;
}

.commissionPaidButtonBed {
  display: flex;
  justify-content: center;
}

.commissionPaidButton {
  background-color: blue;
  color: #ffff;
  text-align: center;
  padding: 7px 13px;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
}

.commissionPaidMark {
  background-color: rgb(43, 43, 43);
  color: #ffff;
  border: 1px solid #ffff;
  font-weight: 300;
  font-size: 3vh;
  border-radius: 7px;
  padding: 7px 13px;
  border-radius: 7px;
  text-align: center;
  margin: 10px;
}

.commissionPaidMarkDate {
  color: #ffff;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}

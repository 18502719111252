.sightDetailsBed {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.sightDetailsInfoItemBed {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sightDetailsHeader {
  color: #ffff;
  font-weight: 400;
  font-size: 1.3rem;
  margin-right: 15px;
}

.sightDetailsText {
  color: #ffff;
  font-weight: 200;
  font-size: 1.3rem;
}

.sightDetailsCashierBed {
  margin-top: 30px;
  margin-bottom: 15px;
}

.sightDetailsCashierHeader {
  color: #ffff;
  font-weight: 400;
  font-size: 1.5rem;
  margin-right: 15px;
  text-align: center;
}

.sightDetailsCashierCellBed {
  display: flex;
  justify-content: center;
}

.sightDetailsCashierCell {
  background-color: #ffff;
  border-radius: 7px;
  padding: 10px;
  margin: 10px;
}

.sightDetailsCashierData {
  margin-top: 5px;
  margin-bottom: 15px;
}

.sightDetailsCashierText {
  color: #000f41;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 300;
}

.sightDetailsClientBed {
  margin-top: 30px;
  margin-bottom: 15px;
}

.sightDetailsClientBed {
  margin-top: 30px;
  margin-bottom: 15px;
}

.sightDetailsClientHeader {
  color: #ffff;
  font-weight: 400;
  font-size: 1.5rem;
  margin-right: 15px;
  text-align: center;
}

.sightDetailsClientCellBed {
  display: flex;
  justify-content: center;
}

.sightDetailsClientCell {
  background-color: #ffff;
  border-radius: 7px;
  padding: 10px;
  margin: 10px;
}

.sightDetailsClientData {
  margin-top: 5px;
  margin-bottom: 15px;
}

.sightDetailsClientText {
  color: #02238f;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 300;
}

/*   */

.siteDetailSiteInfoBed {
  display: flex;
  justify-content: center;
}

.siteDetailCashierBed {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.siteDetailCashierContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #ffff;
  border-radius: 7px;
  padding: 15px;
  margin: 5px;
}

.siteDetailCashierListBed {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.siteDetailEditCashierBed {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.siteDetailCashierSearchBed {
  margin-bottom: 20px;
  margin-top: -20px;
}

.siteDetailErrorSpacer {
  margin-top: 50px;
}

.promoPrizeWheelSwitchBed {
  display: flex;
  justify-content: center;
  margin-top: 6px;
}

.promoPrizeWheelSwitchContainer {
  color: #ffff;
  background-color: #706f6f;
  width: 300px;
  border-radius: 7px;
  padding: 8px 0px;
  text-align: center;
  cursor: pointer;
}

.promoPrizeWheelSwitchText {
  display: flex;
  justify-content: center;
}

.loginBed {
  padding: 3px;
  position: absolute;
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loginContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.loginError {
  color: #ffff;
  background-color: red;
  padding: 10px 15px;
  text-align: center;
  font-size: 18px;
}
.loginFormHeader {
  color: #ffff;
  font-size: 30px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 20px;
}
.loginFormBody {
  display: flex;
  flex-direction: column;
}
.loginInput {
  text-align: center;
  border: 1px solid #ffff;
  border-radius: 25px;
  padding: 10px;
  margin: 10px;
}
.loginFormFooter {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.loginButton {
  background-color: rgb(22, 255, 236);
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

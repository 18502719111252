.adjustPercentageRow {
  display: flex;
  justify-content: space-between;
}

.adjustPercentageText {
  color: #ffff;
  font-size: 1.5rem;
  margin: 5px 10px 5px 5px;
}

.navBed {
  margin-top: 30px;
  display: flex;
}

.navHeaderBed {
  flex: 1;
  align-self: center;
  display: flex;
  justify-content: center;
}

.navBackArrowButton {
  color: #ffff;
  margin-left: 30px;
  font-size: 1.4rem;
  cursor: pointer;
}

.navBackArrowButton:active {
  color: rgb(219, 219, 219);
  margin-left: 30px;
  font-size: 1.4rem;
  cursor: pointer;
}

.navHeader {
  color: #ffff;
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: 100px;
}

.navButtonsBed {
  display: flex;
}

.navSignoutButton {
  color: #ffff;
  font-weight: 200;
  font-size: 1.2rem;
  margin-right: 20px;
}

.navSignoutButton:hover {
  font-weight: 600;
  margin-right: 15px;
}

.navBackArrowButtonBed {
  display: flex;
  cursor: pointer;
}

.navButtonDivider {
  color: #ffff;
  font-weight: 300;
  font-size: 1.2rem;
  margin-left: 10px;
}

.navButtonText {
  color: #ffff;
  font-weight: 300;
  font-size: 1.2rem;
  margin-left: 10px;
  cursor: pointer;
}

.siteBaitTriggerInfoBed {
  border: 1px solid #ffff;
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
}

.siteBaitTriggerInfoHeaderBed {
  display: flex;
  justify-content: center;
}

.baitTriggerDataBed {
  display: flex;
  flex-wrap: wrap;
}

.baitTriggerDataContainer {
  border: 1px solid #ffff;
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.baitTriggerDataLight {
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
}

.baitTriggerDataLightGreen {
  width: 12px;
  height: 12px;
  background-color: rgb(14, 255, 14);
  border-radius: 50%;
}

.baitTriggerDataLightRed {
  width: 12px;
  height: 12px;
  background-color: rgb(255, 50, 50);
  border-radius: 50%;
}

.baitTriggerDataRow {
  display: flex;
  justify-content: center;
  margin: 2px;
}

.baitTriggerDataLabel {
  color: #ffff;
  margin-right: 3px;
}

.baitTriggerDataText {
  color: #ffff;
  font-weight: 100;
}

.clientLengthBed {
  display: flex;
  justify-content: center;
}

.clientLengthText {
  color: #ffff;
  margin-right: 3px;
}

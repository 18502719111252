.clientDetailsHeaderBed {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.clientDetailsHeaderContainer {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.clientDetailsHeaderLabel {
  color: #ffff;
  font-weight: 400;
  font-size: 1.3rem;
  margin-right: 15px;
}

.clientDetailsHeaderText {
  color: #ffff;
  font-weight: 200;
  font-size: 1.3rem;
}
